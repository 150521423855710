<template>
  <b-col cols="12" xl="6">
    <b-form-group :label="$t('order.edit.w_house')" label-for="order-edit-w-house">
      <validation-provider #default="{ errors }" :name="$t('order.edit.w_house')" rules="required">
        <b-overlay :show="!ready" variant="dark" opacity="0.85" blur="2px" rounded="sm">
          <treeselect id="order-edit-w-house" v-model="orderUpdateDetail.w_house_code" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="items" :class="errors.length > 0 ? 'is-invalid' : ''" />
        </b-overlay>
        <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
          {{ errors[0] }}
        </b-form-invalid-feedback>
      </validation-provider>
    </b-form-group>
  </b-col>
</template>

<script>
import { BCol, BFormGroup, BOverlay, BFormInvalidFeedback } from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate/dist/vee-validate.full'
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import '@/views/Report/components/scss/treeselect.scss'
import { mapGetters } from 'vuex'
import { GET_WHOUSE_CODES } from '@/store/services/order-service'

export default {
  name: 'order-edit-w-house',
  components: {
    BCol,
    BFormGroup,
    BOverlay,
    BFormInvalidFeedback,

    ValidationProvider,
    Treeselect
  },
  data() {
    return {
      ready: false,
      items: []
    }
  },
  created() {
    this.$store.dispatch(GET_WHOUSE_CODES).then(response => {
      response.forEach(item => {
        this.items.push({
          id: item.WhouseCode,
          label: item.WhouseName
        })
      })
      this.ready = true
    })
  },
  computed: {
    ...mapGetters(['orderUpdateDetail'])
  }
}
</script>
