<template>
  <div class="mt-md-0 mt-2">
    <h4 class="invoice-title">
      {{ $t('order.no') }}
      <span class="invoice-number">
        {{ `#${orderDetail.details.order_no}` }}
      </span>
    </h4>
    <div class="invoice-date-wrapper">
      <p class="invoice-date-title">
        {{ $t('order.date') }}
      </p>
      <p class="invoice-date">{{ orderDate }}</p>
    </div>
    <div class="invoice-date-wrapper">
      <p class="invoice-date-title">{{ $t('order.state') }}:</p>
      <p class="invoice-date">
        {{ $t('order.' + orderDetail.details.order_state) }}
      </p>
    </div>
    <div class="invoice-date-wrapper">
      <p class="invoice-date-title">{{ $t('shop.payment_type') }}:</p>
      <p class="invoice-date">
        {{ $t(`shop.payment_type_${orderDetail.details.payment_type}`) }}
      </p>
    </div>
    <div v-if="orderDetail.details.payment_type == 1 && orderDetail.details.transaction_m_id" class="invoice-date-wrapper">
      <p class="invoice-date-title">{{ $t('transaction.no') }}</p>
      <router-link :to="{ name: '/Payment/TransactionDetail', params: { id: transactionID } }" target="_blank" class="invoice-date">
        {{ `#${transactionID}` }}
      </router-link>
    </div>
    <div class="invoice-date-wrapper">
      <p class="invoice-date-title font-weight-bolder">{{ $t('order.currency_info') }}</p>
    </div>
    <div v-for="(item, index) in orderDetail.currencyList" :key="`order_detail_currency_${index}`" class="invoice-date-wrapper">
      <p class="invoice-date-title">{{ item.code }}:</p>
      <p class="invoice-date">
        {{ showCurrenyRate(item.ask) }}
      </p>
    </div>
  </div>
</template>

<script>
import { priceFormat } from '@/assets/js/functions/currency-functions'
import moment from 'moment'
import { mapGetters } from 'vuex'
export default {
  name: 'order-detail-info',
  computed: {
    ...mapGetters(['orderDetail', 'getLocale']),
    orderDate() {
      return moment(this.orderDetail.details.order_date).format('DD.MM.YYYY HH:mm')
    },
    transactionID() {
      let temp = this.orderDetail?.details?.transaction_master?.transaction_detail
      if (temp?.length) {
        return temp[temp.length - 1].transaction_id
      }
      return ''
    }
  },
  methods: {
    showCurrenyRate(rate) {
      return new Intl.NumberFormat(this.getLocale, {
        maximumFractionDigits: 4,
        minimumFractionDigits: 4
      }).format(rate)
    }
  }
}
</script>
