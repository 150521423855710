<template>
  <b-col cols="12" xl="6" class="p-0">
    <h6>{{ $t('order.customer_trade_name') }}:</h6>
    <div class="logo-wrapper">
      <h3 class="text-primary invoice-logo">
        {{ orderDetail.details.giver_company.company_trade_name }}
      </h3>
    </div>
    <h6>{{ $t('address.shipping_address') }}:</h6>

    <template v-if="orderDetail.shippingAddress.CO_ENTITY_ADDRESS_ID">
      <p class="card-text mb-25">
        {{ orderDetail.shippingAddress.ADDRESS1 }}
        {{ orderDetail.shippingAddress.ADDRESS2 }}
        {{ orderDetail.shippingAddress.ADDRESS3 }}
      </p>

      <p class="card-text mb-25">
        {{ orderDetail.shippingAddress.ZIP_CODE }} - {{ orderDetail.shippingAddress.TOWN_NAME }} /
        {{ orderDetail.shippingAddress.CITY_NAME }}
        {{ orderDetail.shippingAddress.COUNTRY_NAME }}
      </p>
      <p v-if="orderDetail.shippingAddress.MOBILE_TEL !== ''" class="card-text mb-0">
        {{ phoneFormat(orderDetail.shippingAddress.MOBILE_TEL) }}
      </p>
      <p v-if="orderDetail.shippingAddress.TEL1 !== ''" class="card-text mb-0">
        {{ phoneFormat(orderDetail.shippingAddress.TEL1) }}
      </p>
      <p v-if="orderDetail.shippingAddress.TEL2 !== ''" class="card-text mb-0">
        {{ phoneFormat(orderDetail.shippingAddress.TEL2) }}
      </p>
    </template>

    <template v-else>
      <p class="card-text mb-25">
        {{ orderDetail.customerAddress.address_line1 }}
        {{ orderDetail.customerAddress.address_line2 }}
        {{ orderDetail.customerAddress.address_line3 }}
      </p>
      <p class="card-text mb-25">
        {{ orderDetail.customerAddress.post_code }} - {{ orderDetail.customerAddress.town.town_name }} /
        {{ orderDetail.customerAddress.city.city_name }}
        {{ orderDetail.customerAddress.country.country_name }}
      </p>
      <p class="card-text mb-0">
        {{ phoneFormat(orderDetail.customerAddress.phone) }}
      </p>
      <p class="card-text mb-0">
        {{ orderDetail.customerAddress.e_mail }}
      </p>
    </template>
  </b-col>
</template>

<script>
import { BCol } from 'bootstrap-vue'
import { mapGetters } from 'vuex'

export default {
  name: 'order-detail-customer-address',
  components: { BCol },
  computed: {
    ...mapGetters(['orderDetail'])
  },
  methods: {
    phoneFormat(phone) {
      return `+90 ${phone.slice(0, 3)} ${phone.slice(3, 6)} 
      ${phone.slice(6, 8)} ${phone.slice(8, 10)}`
    }
  }
}
</script>
