<template>
  <b-col cols="12">
    <b-form-group
      :label="$t('order.edit.description')"
      label-for="order-edit-description"
    >
      <b-form-textarea
        id="order-edit-description"
        :placeholder="$t('order.edit.description_placeholder')"
        v-model="orderUpdateDetail.description"
        rows="2"
        no-resize
      />
    </b-form-group>
  </b-col>
</template>

<script>
import { BCol, BFormGroup, BFormTextarea } from "bootstrap-vue";
import { mapGetters } from "vuex";

export default {
  name: "order-edit-description",
  components: {
    BCol,
    BFormGroup,
    BFormTextarea,
  },
  computed: {
    ...mapGetters(["orderUpdateDetail"]),
  },
};
</script>