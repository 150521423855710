<template>
  <b-col cols="6">
    <b-form-group :label="$t('order.edit.due_date')" label-for="order-edit-due-date" v-show="orderUpdateDetail.due_date">
      <h4 class="font-weight-bold due-date">
        {{ showDate() }}
      </h4>
    </b-form-group>
  </b-col>
</template>

<style lang="scss">
.due-date {
  line-height: 38px;
}
</style>

<script>
import { BCol, BFormGroup } from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import moment from 'moment'

export default {
  name: 'order-edit-due-date',
  components: {
    BCol,
    BFormGroup
  },
  computed: {
    ...mapGetters(['orderUpdateDetail'])
  },
  methods: {
    showDate() {
      return moment(this.orderUpdateDetail.due_date).format('DD.MM.YYYY')
    }
  }
}
</script>
