<template>
  <b-modal ref="saveOrderModal" hide-footer centered no-close-on-backdrop size="lg" :title="$t('order.edit.save_title')">
    <b-row>
      <b-col cols="12" class="save-warning">
        <b-card-text class="d-flex justify-content-between align-items-center">
          <span>{{ $t('shop.payment_type') }}</span>
          <span>
            {{ $t('shop.payment_type_' + orderDetail.details.payment_type) }}
          </span>
        </b-card-text>
        <hr />
        <div v-if="orderDetail.details.payment_type == 2 && ready">
          <b-card-text class="d-flex justify-content-between align-items-center">
            <span>{{ $t('order.edit.pre_confirm_state') }}</span>
            <span :class="`text-${balanceStateClass()} font-weight-bold`">
              {{ priceFormat(data.BALANCE) }}
            </span>
          </b-card-text>
          <b-card-text class="d-flex justify-content-between align-items-center">
            <span>{{ $t('order.edit.order_amount') }}</span>
            <span :class="`text-danger font-weight-bold`">
              {{ priceFormat(cartTotal()) }}
            </span>
          </b-card-text>
          <hr />
          <b-card-text class="d-flex justify-content-between align-items-center">
            <span>{{ $t('order.edit.on_confirm_state') }}</span>
            <span :class="`text-danger font-weight-bold`">
              {{ priceFormat(orderTotal()) }}
            </span>
          </b-card-text>
          <b-alert :variant="orderStateClass()" show>
            <div class="alert-body">
              <span>
                {{ $t(`order.edit.transaction_state_${orderState}`) }}
              </span>
            </div>
          </b-alert>
          <hr />
        </div>
        <b-card-text class="text-center mb-1">
          {{ $t('order.edit.order_edit_warning') }}
        </b-card-text>
      </b-col>
      <b-col cols="12" class="d-flex justify-content-between align-items-center">
        <b-button variant="danger" class="mb-75 mr-1" @click="hideModal()">
          {{ $t('order.edit.save_cancel') }}
        </b-button>
        <b-button variant="success" class="mb-75 mr-1" @click="saveOrder()">
          {{ $t('order.edit.save_confirm') }}
        </b-button>
      </b-col>
    </b-row>
  </b-modal>
</template>

<style lang="scss">
.save-warning {
  min-height: 250px;
}
</style>

<script>
import { BRow, BCol, BModal, BCardText, BButton, BAlert } from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import { GET_COMPANY_BALANCE } from '@/store/services/company-service'
import { convertTL, priceFormat, priceWithVat } from '@/assets/js/functions/currency-functions'
import { CONFIRM_ORDER } from '@/store/services/order-service'

export default {
  name: 'order-edit-save-modal',
  components: { BRow, BCol, BModal, BCardText, BButton, BAlert },
  data() {
    return {
      data: undefined,
      ready: false,
      orderState: 0
    }
  },
  created() {
    switch (this.orderDetail.details.payment_type) {
      case 1:
      case 3:
        break
      case 2:
        this.$store.dispatch(GET_COMPANY_BALANCE, this.$route.params.id * 1).then(response => {
          this.data = response
          let temp = this.orderTotal()
          if (temp > 0) {
            this.orderState = 0
          } else {
            this.orderState = 1
          }
          this.ready = true
        })
        break
    }
  },
  computed: {
    ...mapGetters(['orderDetail'])
  },
  methods: {
    openModal() {
      this.$refs.saveOrderModal.show()
    },
    hideModal() {
      this.$refs.saveOrderModal.hide()
    },
    saveOrder() {
      this.$store
        .dispatch(CONFIRM_ORDER, this.$route.params.id * 1)
        .then(response => {
          this.$router.push({ name: '/Shop/Orders' })
        })
        .catch(() => {})
        .finally(() => {
          this.hideModal()
        })
    },
    priceFormat(amount) {
      let temp = Math.abs(amount)
      return priceFormat(temp, this.getLocale, 'TRY')
    },
    balanceState() {
      let temp = this.data.BALANCE
      if (temp > 0) {
        return 1
      } else if (temp < 0) {
        return 0
      } else {
        return 2
      }
    },
    balanceStateClass() {
      let temp = this.balanceState()
      switch (temp) {
        case 0:
          return 'danger'
        case 1:
          return 'success'
        case 2:
          return 'secondary'
      }
    },
    orderStateClass() {
      switch (this.orderState) {
        case 0:
          return 'success'
        case 1:
          return 'danger'
      }
    },
    cartTotal() {
      let total = 0
      this.orderDetail.details.order_detail.forEach(item => {
        let price = item.unit_price_tra * item.quantity
        let type = item.currency.currency_code
        let rate = item.vat_rate
        let currency = {}
        currency[type] = { satis: item.currency_rate }
        price = convertTL(price, type, currency)
        total += priceWithVat(price, rate)
      })
      return total
    },
    orderTotal() {
      let cart = this.cartTotal()
      let balance = this.data.BALANCE

      return balance - cart
    }
  }
}
</script>
