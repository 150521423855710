<template>
  <div>
    <div class="logo-wrapper">
      <h3 class="text-primary invoice-logo">
        {{ orderDetail.details.taker_company.company_trade_name }}
      </h3>
    </div>
    <p class="card-text mb-0">
      {{ orderDetail.details.taker_company.tax_number }} -
      {{ orderDetail.details.taker_company.tax_office.tax_office_name }}
    </p>
    <p class="card-text mb-25">
      {{ orderDetail.invoiceAddress.address_line1 }}
      {{ orderDetail.invoiceAddress.address_line2 }}
      {{ orderDetail.invoiceAddress.address_line3 }}
    </p>
    <p class="card-text mb-25">
      {{ orderDetail.invoiceAddress.post_code }}
      -
      {{ orderDetail.invoiceAddress.town.town_name }}
      /
      {{ orderDetail.invoiceAddress.city.city_name }}
      {{ orderDetail.invoiceAddress.country.country_name }}
    </p>
    <p class="card-text mb-0">
      {{ phoneFormat(orderDetail.invoiceAddress.phone) }}
    </p>
    <p class="card-text mb-0">
      {{ orderDetail.invoiceAddress.e_mail }}
    </p>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "order-detail-taker-address",
  computed: {
    ...mapGetters(["orderDetail"]),
  },
  methods: {
    phoneFormat(phone) {
      return `+90 ${phone.slice(0, 3)} ${phone.slice(3, 6)} 
      ${phone.slice(6, 8)} ${phone.slice(8, 10)}`;
    },
  },
};
</script>