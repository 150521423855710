<template>
  <b-col
    xl="6"
    cols="12"
    class="p-0 mt-xl-0 mt-2 d-flex justify-content-end pr-xl-2"
  >
    <div>
      <h6>{{ $t("order.created_by") }}:</h6>
      <table>
        <tbody>
          <tr>
            <td class="pr-1">{{ $t("order.cb_name") }}:</td>
            <td>
              {{ orderDetail.details.user.name }}
              {{ orderDetail.details.user.surname }}
            </td>
          </tr>
          <tr>
            <td class="pr-1">{{ $t("order.cb_phone") }}:</td>
            <td>
              {{ phoneFormat(orderDetail.details.user.gsm_number) }}
            </td>
          </tr>
          <tr>
            <td class="pr-1">{{ $t("order.cb_email") }}:</td>
            <td>
              {{ orderDetail.details.user.email }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </b-col>
</template>

<script>
import { BCol } from "bootstrap-vue";
import { mapGetters } from "vuex";

export default {
  name: "order-detail-created-by",
  components: { BCol },
  computed: {
    ...mapGetters(["orderDetail"]),
  },
  methods: {
    phoneFormat(phone) {
      return `+90 ${phone.slice(0, 3)} ${phone.slice(3, 6)} 
      ${phone.slice(6, 8)} ${phone.slice(8, 10)}`;
    },
  },
};
</script>