<template>
  <b-col cols="12" xl="6">
    <b-form-group :label="$t('order.edit.doc_tra_code')" label-for="order-edit-doc-tra-code">
      <validation-provider #default="{ errors }" :name="$t('order.edit.doc_tra_code')" rules="required">
        <b-overlay :show="!ready" variant="dark" opacity="0.85" blur="2px" rounded="sm">
          <treeselect
            id="order-edit-doc-tra-code"
            v-model="orderUpdateDetail.doc_tra_code"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="items"
            :class="errors.length > 0 ? 'is-invalid' : ''"
            @select="changeDocTraCode"
            :beforeClearAll="deselectDocTraCode"
          />
        </b-overlay>
        <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
          {{ errors[0] }}
        </b-form-invalid-feedback>
      </validation-provider>
    </b-form-group>
  </b-col>
</template>

<script>
import { BCol, BFormGroup, BOverlay, BFormInvalidFeedback } from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate/dist/vee-validate.full'
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import '@/views/Report/components/scss/treeselect.scss'
import { mapGetters } from 'vuex'
import { GET_DOC_TRA_CODES } from '@/store/services/order-service'

export default {
  name: 'order-edit-doc-tra-code',
  components: {
    BCol,
    BFormGroup,
    BOverlay,
    BFormInvalidFeedback,

    ValidationProvider,
    Treeselect
  },
  data() {
    return {
      ready: false,
      items: []
    }
  },
  created() {
    this.$store.dispatch(GET_DOC_TRA_CODES).then(response => {
      response.forEach(item => {
        this.items.push({
          id: item.DocTraCode,
          label: item.DocTraName,
          confirm: {
            one: item.OrderConfirm1.trim(),
            two: item.OrderConfirm2.trim()
          },
          PurchaseSales: item.PurchaseSales,
          SourceApp: item.SourceApp,
          SourceApp2: item.SourceApp2,
          UserCode: item.UserCode
        })
      })
      this.ready = true
    })
  },
  computed: {
    ...mapGetters(['orderUpdateDetail'])
  },
  methods: {
    changeDocTraCode(node) {
      this.orderUpdateDetail.confirm_1 = node.confirm.one
      this.orderUpdateDetail.confirm_2 = node.confirm.two

      this.orderUpdateDetail.PurchaseSales = node.PurchaseSales
      this.orderUpdateDetail.SourceApp = node.SourceApp
      this.orderUpdateDetail.SourceApp2 = node.SourceApp2
      this.orderUpdateDetail.UserCode = node.UserCode
    },
    deselectDocTraCode() {
      this.orderUpdateDetail.doc_tra_code = undefined
      this.orderUpdateDetail.confirm_1 = undefined
      this.orderUpdateDetail.confirm_2 = undefined

      this.orderUpdateDetail.PurchaseSales = ''
      this.orderUpdateDetail.SourceApp = ''
      this.orderUpdateDetail.SourceApp2 = ''
      this.orderUpdateDetail.UserCode = ''
    }
  }
}
</script>
