<template>
  <b-card-body v-if="orderDetail.details.transaction_master" class="invoice-padding pb-0">
    <b-row>
      <!-- Col: Sales Persion -->
      <b-col cols="12" md="6" class="mt-md-0 mt-3" order="2" order-md="1">
        <b-card-text class="mb-0">
          <span class="font-weight-bold">
            {{ $t('transaction.bank_response') }}
          </span>
          <span class="ml-75">
            {{ orderDetail.lastTransaction.description }}
          </span>
        </b-card-text>
      </b-col>

      <!-- Col: Total -->
      <b-col cols="12" md="6" class="mt-md-6 d-flex justify-content-end mb-3" order="1" order-md="2">
        <div class="invoice-total-wrapper">
          <div class="invoice-total-item">
            <p class="invoice-total-title">
              {{ this.$t('transaction.amount') }}
            </p>
            <p class="invoice-total-amount">
              {{ amount(orderDetail.details.transaction_master) }}
            </p>
          </div>
          <div class="invoice-total-item">
            <p class="invoice-total-title">
              {{ this.$t('transaction.commission') }}
            </p>
            <p class="invoice-total-amount">
              {{ commission(orderDetail.details.transaction_master) }}
            </p>
          </div>
          <hr class="my-50" style="width: 250px" />
          <div class="invoice-total-item">
            <p class="invoice-total-title">
              {{ this.$t('transaction.total_amount') }}
            </p>
            <p class="invoice-total-amount">
              {{ total(orderDetail.details.transaction_master) }}
            </p>
          </div>
        </div>
      </b-col>
    </b-row>
  </b-card-body>
</template>

<script>
import { BCardBody, BCardText, BRow, BCol } from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import { priceFormat } from '@/assets/js/functions/currency-functions'

export default {
  name: 'order-detail-cc-amount',
  components: {
    BCardBody,
    BCardText,
    BRow,
    BCol
  },
  computed: {
    ...mapGetters(['orderDetail'])
  },
  methods: {
    amount(transaction) {
      let price = transaction.amount
      return priceFormat(price, this.getLocale, 'TRY')
    },
    commission(transaction) {
      let price = transaction.amount * (transaction.commission / 100)
      return priceFormat(price, this.getLocale, 'TRY')
    },
    total(transaction) {
      let price = transaction.total_amount
      return priceFormat(price, this.getLocale, 'TRY')
    }
  }
}
</script>
