<template>
  <b-card-body class="invoice-padding pt-0">
    <b-row class="invoice-spacing">
      <!-- Col: Invoice To -->
      <b-col cols="12" md="6" class="p-0">
        <h6>{{ $t('address.invoice_address') }}:</h6>
        <p class="card-text mb-0">
          {{ orderDetail.details.giver_company.tax_number }} -
          {{ orderDetail.details.giver_company.tax_office.tax_office_name }}
        </p>
        <p class="card-text mb-25">
          {{ orderDetail.giverAddress.address_line1 }}
          {{ orderDetail.giverAddress.address_line2 }}
          {{ orderDetail.giverAddress.address_line3 }}
        </p>
        <p class="card-text mb-25">
          {{ orderDetail.giverAddress.post_code }} - {{ orderDetail.giverAddress.town.town_name }} /
          {{ orderDetail.giverAddress.city.city_name }}
          {{ orderDetail.giverAddress.country.country_name }}
        </p>
        <p class="card-text mb-0">
          {{ phoneFormat(orderDetail.giverAddress.phone) }}
        </p>
        <p class="card-text mb-0">
          {{ orderDetail.giverAddress.e_mail }}
        </p>
      </b-col>
      <!-- Col: Payment Details -->
      <b-col md="6" cols="12" class="p-0 mt-xl-0 mt-2 d-flex justify-content-end pr-xl-2">
        <div>
          <h6>
            {{ this.$t('transaction.payment_detail') }}
          </h6>
          <table>
            <tbody>
              <tr>
                <td class="pr-1">
                  {{ this.$t('transaction.bank_name') }}
                </td>
                <td>
                  {{ orderDetail.details.transaction_master.bank_id ? orderDetail.details.transaction_master.bank_id : $t('payment.table.undefined') }}
                </td>
              </tr>
              <tr>
                <td class="pr-1">
                  {{ this.$t('transaction.ip_address') }}
                </td>
                <td>
                  {{ orderDetail.lastTransaction.ip_address }}
                </td>
              </tr>
              <tr>
                <td class="pr-1">
                  {{ this.$t('transaction.type') }}
                </td>
                <td>
                  {{ orderDetail.lastTransaction.transaction_type.transaction_type_name }}
                </td>
              </tr>
              <tr>
                <td class="pr-1">
                  {{ this.$t('transaction.state') }}
                </td>
                <td>
                  {{ orderDetail.lastTransaction.transaction_state.transaction_state_name }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </b-col>
    </b-row>
  </b-card-body>
</template>

<script>
import { BCardBody, BRow, BCol } from 'bootstrap-vue'
import { mapGetters } from 'vuex'

export default {
  name: 'order-detail-cc-giver-address',
  components: {
    BCardBody,
    BRow,
    BCol
  },
  computed: {
    ...mapGetters(['orderDetail'])
  },
  methods: {
    phoneFormat(phone) {
      return `+90 ${phone.slice(0, 3)} ${phone.slice(3, 6)} 
      ${phone.slice(6, 8)} ${phone.slice(8, 10)}`
    }
  }
}
</script>
