<template>
  <b-table-lite responsive :items="[orderDetail.details.transaction_master]" :fields="paymentDetailFields" class="detail-table">
    <template #cell(cc_holder)="data">
      {{ data.item.credit_card_holder }}
    </template>
    <template #cell(cc_no)="data">
      {{ data.item.credit_card_no }}
    </template>
    <template #cell(ins)="data">
      {{ data.item.installment_count }}
    </template>
    <template #cell(e_ins)="data">
      {{ data.item.extra_installment_count }}
    </template>
    <template #cell(com)="data">
      {{ showRate(data.item.commission) }}
    </template>
    <template #cell(total)="data">
      {{ showPrice(data.item.total_amount) }}
    </template>
  </b-table-lite>
</template>

<script>
import { BTableLite } from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import { priceFormat } from '@/assets/js/functions/currency-functions'

export default {
  name: 'order-detail-cc-detail',
  components: {
    BTableLite
  },
  computed: {
    ...mapGetters(['orderDetail']),
    paymentDetailFields() {
      return [
        {
          key: 'cc_holder',
          label: this.$t('transaction.credit_card_holder'),
          tdClass: 'text-nowrap text-uppercase'
        },
        {
          key: 'cc_no',
          label: this.$t('transaction.credit_card'),
          tdClass: 'text-nowrap'
        },
        {
          key: 'ins',
          label: this.$t('transaction.installment'),
          tdClass: 'text-right',
          thClass: 'text-right'
        },
        {
          key: 'e_ins',
          label: this.$t('transaction.extra_installment'),
          tdClass: 'text-right',
          thClass: 'text-right'
        },
        {
          key: 'com',
          label: this.$t('transaction.commission'),
          tdClass: 'text-right',
          thClass: 'text-right'
        },
        {
          key: 'total',
          label: this.$t('transaction.total'),
          tdClass: 'text-right text-nowrap',
          thClass: 'text-right'
        }
      ]
    }
  },
  methods: {
    showPrice(price) {
      return priceFormat(price, this.getLocale, 'TRY')
    },
    showRate(rate) {
      return (
        new Intl.NumberFormat(this.getLocale, {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2
        }).format(rate) + ' %'
      )
    }
  }
}
</script>
