<template>
  <b-overlay :show="!ready" variant="dark" opacity="0.85" blur="2px" rounded="sm">
    <b-row class="d-flex justify-content-center" v-if="orderDetail.details.order_state === 1">
      <b-col cols="12" md="10" xl="9">
        <b-card no-body>
          <b-card-header>
            <h1>{{ $t('order.edit.title_erp') }}</h1>
          </b-card-header>
          <b-card-body>
            <validation-observer ref="orderEdit" v-slot="{ invalid }">
              <b-row>
                <b-col cols="12" xl="6">
                  <b-row>
                    <w-house />
                    <doc-tra-code />
                  </b-row>
                  <b-row>
                    <due-day />
                    <due-date />
                  </b-row>
                  <b-row>
                    <order-description />
                  </b-row>
                  <b-row>
                    <b-col cols="12" class="d-flex justify-content-between print-button-div" @mouseenter="checkForm()">
                      <b-button variant="danger" class="mb-75 mr-1" @click="cancelOrder()">
                        {{ $t('order.edit.cancel') }}
                      </b-button>
                      <b-button variant="success" class="mb-75" @click="saveOrder()" :disabled="invalid">
                        {{ $t('order.edit.save') }}
                      </b-button>
                      <save-order-modal ref="saveModal" v-if="orderDetail.details.payment_type" />
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </validation-observer>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>

    <section class="invoice-preview-wrapper" v-if="orderDetail.ready">
      <b-row class="invoice-preview d-flex justify-content-center">
        <!-- Col: Left (Invoice Container) -->
        <b-col cols="12" md="10" xl="9" v-if="ready">
          <b-card no-body class="invoice-preview-card">
            <!-- Header -->
            <b-card-body class="invoice-padding pb-0">
              <div
                class="
                  d-flex
                  justify-content-between
                  flex-md-row flex-column
                  invoice-spacing
                  mt-0
                "
              >
                <!-- Header: Left Content -->
                <taker-address />
                <!-- Header: Right Content -->
                <order-info />
              </div>
            </b-card-body>

            <!-- Invoice Client & Payment Details -->
            <b-card-body class="invoice-padding pt-0">
              <b-row class="invoice-spacing">
                <!-- Col: Invoice To -->
                <customer-address />
                <!-- Col: Created By -->
                <order-created-by />
              </b-row>
            </b-card-body>

            <order-products />

            <div v-if="orderDetail.details.payment_type === 1">
              <!-- Invoice Client & Payment Details -->
              <cc-giver-address />
              <!-- Ödeme Detay -->
              <cc-detail />
              <!-- Invoice Description: Total -->
              <cc-amount />
            </div>
          </b-card>
        </b-col>
      </b-row>
    </section>
    <b-alert variant="danger" :show="ready" v-else>
      <h4 class="alert-heading d-flex justify-content-between">
        <div>
          {{ $t('order.detail') }}
        </div>
        <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="none" size="sm" @click="getOrderDetail()">
          <i class="fal fa-sync-alt text-danger" />
        </b-button>
      </h4>
      <div class="alert-body">
        <span>{{ $t('notifications.get_order_detail_not_found') }}</span>
      </div>
    </b-alert>
  </b-overlay>
</template>

<script>
import { BRow, BCol, BCard, BCardHeader, BCardBody, BTableLite, BCardText, BButton, BAlert, BLink, VBToggle, BOverlay } from 'bootstrap-vue'
import TakerAddress from '@/views/Order/Detail/TakerAddress.vue'
import OrderInfo from '@/views/Order/Detail/Info.vue'
import OrderProducts from '@/views/Order/Detail/Products.vue'
import CustomerAddress from '@/views/Order/Detail/CustomerAddress.vue'
import OrderCreatedBy from '@/views/Order/Detail/CreatedBy.vue'
import CcGiverAddress from '@/views/Order/Detail/CCGiverAddress.vue'
import CcDetail from '@/views/Order/Detail/CCDetail.vue'
import CcAmount from '@/views/Order/Detail/CCAmount.vue'

import DocTraCode from './DocTraCode.vue'
import WHouse from './WHouse.vue'
import SaveOrderModal from './SaveModal.vue'
import DueDay from './DueDay.vue'
import DueDate from './DueDate.vue'
import OrderDescription from './Description.vue'

import { ValidationObserver } from 'vee-validate'

import Ripple from 'vue-ripple-directive'
import { CANCEL_ORDER, GET_ORDER_DETAIL } from '@/store/services/order-service'
import { mapGetters } from 'vuex'

export default {
  name: 'order-detail',
  directives: {
    Ripple,
    'b-toggle': VBToggle
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BTableLite,
    BCardText,
    BButton,
    BAlert,
    BLink,
    VBToggle,
    BOverlay,

    ValidationObserver,

    TakerAddress,
    OrderInfo,
    OrderProducts,
    CustomerAddress,
    OrderCreatedBy,
    CcGiverAddress,
    CcDetail,
    CcAmount,

    DocTraCode,
    WHouse,
    SaveOrderModal,
    DueDay,
    DueDate,
    OrderDescription
  },
  data() {
    return {
      orderMId: this.$route.params.id,
      ready: false
    }
  },
  created() {
    this.getOrderDetail()
  },
  computed: {
    ...mapGetters(['currency', 'getLocale', 'orderDetail'])
  },
  methods: {
    getOrderDetail() {
      this.ready = false
      this.$store.dispatch(GET_ORDER_DETAIL, this.orderMId).then(() => {
        this.ready = true
      })
    },
    checkForm() {
      this.$refs.orderEdit.validate()
    },
    cancelOrder() {
      this.$swal({
        title: this.$t('order.edit.cancel_title'),
        html: this.$t('order.edit.cancel_message'),
        icon: 'warning',
        customClass: {
          denyButton: 'btn btn-secondary',
          confirmButton: 'btn btn-danger mr-2'
        },
        showDenyButton: true,
        denyButtonText: this.$t('No'),
        confirmButtonText: this.$t('Yes'),
        buttonsStyling: false
      }).then(res => {
        if (res.value) {
          this.$store
            .dispatch(CANCEL_ORDER, this.$route.params.id * 1)
            .then(() => {
              this.$router.push({ name: '/Shop/Orders' })
            })
            .catch(() => {})
        }
      })
    },
    saveOrder() {
      this.$refs.saveModal.openModal()
    }
  }
}
</script>

<style lang="scss">
@import '~@core/scss/base/pages/app-invoice.scss';
.invoice-total-wrapper {
  margin-right: 100px;
}
.invoice-total-item {
  width: 250px;
}
.invoice-logo {
  max-width: 500px;
  font-size: 1.25rem !important;
  margin: 0 !important;
}
.invoice-date-title {
  width: auto !important;
}
</style>

<style lang="scss">
.product-name {
  max-width: 250px;
}
</style>
