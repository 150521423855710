<template>
  <b-col cols="6">
    <b-form-group :label="$t('order.edit.due_day')" label-for="order-edit-due-day">
      <validation-provider #default="{ errors }" :name="$t('order.edit.due_day')" rules="required">
        <cleave
          v-model="orderUpdateDetail.due_day"
          :options="cleaveOptions"
          class="form-control"
          id="order-edit-due-day"
          :class="{
            'is-invalid': errors.length > 0
          }"
          @input="changeDate()"
        />
        <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
          {{ errors[0] }}
        </b-form-invalid-feedback>
      </validation-provider>
    </b-form-group>
  </b-col>
</template>

<script>
import { BCol, BFormGroup, BFormInvalidFeedback } from 'bootstrap-vue'
import Cleave from 'vue-cleave-component'
import { ValidationProvider } from 'vee-validate/dist/vee-validate.full'
import { mapGetters } from 'vuex'
import moment from 'moment'

export default {
  name: 'order-edit-due-day',
  components: {
    BCol,
    BFormGroup,
    BFormInvalidFeedback,

    Cleave,
    ValidationProvider
  },
  computed: {
    ...mapGetters(['orderUpdateDetail', 'getLocale']),
    cleaveOptions() {
      switch (this.getLocale) {
        case 'tr-TR':
          return {
            numeral: true,
            numeralPositiveOnly: true,
            numeralDecimalScale: 0,
            numeralDecimalMark: ',',
            delimiter: '.'
          }
        default:
          return {
            numeral: true,
            numeralPositiveOnly: true,
            numeralDecimalScale: 0,
            numeralDecimalMark: '.',
            delimiter: ','
          }
      }
    }
  },
  methods: {
    changeDate() {
      if (this.orderUpdateDetail.due_day) {
        this.orderUpdateDetail.due_date = moment()
          .add(this.orderUpdateDetail.due_day, 'days')
          .format('YYYY-MM-DD')
      } else {
        this.orderUpdateDetail.due_date = undefined
      }
    }
  }
}
</script>
