<template>
  <b-table-lite responsive :items="orderDetail.details.order_detail" :fields="orderDetailFields" class="detail-table">
    <template #cell(index)="data">
      {{ data.index + 1 }}
    </template>
    <template #cell(barcode)="data">
      {{ data.item.product.barcode }}
    </template>
    <template #cell(p_name)="data">
      <p class="mb-0">
        {{ data.item.product.product_name }}
        <template v-if="data.item.product.attribute_name">
          <br />
          <span>{{ data.item.product.attribute_name }}</span>
        </template>
      </p>
    </template>
    <template #cell(p_unit)="data">
      {{ data.item.unit.unit_name }}
    </template>
    <template #cell(p_quantity)="data">
      {{ data.item.quantity }}
    </template>
    <template #cell(p_unit_price)="data">
      {{ productPrice(data.item) }}
    </template>
    <template #cell(p_vat)="data">
      {{ productVat(data.item) }}
    </template>
    <template #cell(p_total)="data">
      {{ productPriceWithVAT(data.item) }}
    </template>
    <template #table-caption>
      <hr />
      <div class="d-flex justify-content-end">
        <table style="width: 35rem;">
          <thead>
            <tr>
              <th class="w-100"></th>
              <th class="text-center">
                <h5 class="font-weight-bolder">USD</h5>
              </th>
              <th class="text-center">
                <h5 class="font-weight-bolder">TRY</h5>
              </th>
            </tr>
          </thead>
          <tbody :set="(priceDetail = getPriceDetail(orderDetail.details.order_detail, orderDetail.details.payment_type))">
            <tr>
              <th>
                <h5 class="font-weight-bolder">{{ $t('shop.total') }}</h5>
              </th>
              <td class="text-right text-nowrap font-weight-bolder">{{ priceDetail.USD_SHOW }}</td>
              <td class="text-right text-nowrap font-weight-bolder text-warning">{{ priceDetail.TOTAL_TRY_SHOW }}</td>
            </tr>
            <tr v-if="orderDetail.details.payment_type === 1">
              <th>
                <h5 class="font-weight-bolder">{{ $t('shop.discount') }}</h5>
              </th>
              <td class="text-right text-nowrap font-weight-bolder">{{ priceDetail.DISCOUNT_SHOW }}</td>
              <td class="text-right text-nowrap font-weight-bolder text-warning">{{ priceDetail.DISCOUNT_TRY_SHOW }}</td>
            </tr>
            <tr class="border-bottom" style="border-width: 2px!important;">
              <th>
                <h5 class="font-weight-bolder">{{ $t('shop.vat') }}</h5>
              </th>
              <td class="text-right text-nowrap font-weight-bolder">{{ priceDetail.USD_VAT_SHOW }}</td>
              <td class="text-right text-nowrap font-weight-bolder text-warning">{{ priceDetail.TOTAL_TRY_VAT_SHOW }}</td>
            </tr>
            <tr>
              <th>
                <h5 class="font-weight-bolder">{{ $t('shop.order_total') }}</h5>
              </th>
              <td class=" text-right text-nowrap font-weight-bolder">{{ priceDetail.USD_PWVD_SHOW }}</td>
              <td class=" text-right text-nowrap font-weight-bolder text-warning">{{ priceDetail.TOTAL_TRY_PWVD_SHOW }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <hr />
    </template>
  </b-table-lite>
</template>

<script>
import { BTableLite } from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import { convertTL, priceFormat, priceVat, priceWithVat } from '@/assets/js/functions/currency-functions'

export default {
  name: 'order-detail-product',
  components: { BTableLite },
  computed: {
    ...mapGetters(['orderDetail', 'getLocale']),
    orderDetailFields() {
      return [
        {
          key: 'index',
          label: 'No',
          tdClass: 'align-middle text-center',
          thClass: 'align-middle text-center'
        },
        {
          key: 'barcode',
          label: this.$t('transaction.barcode'),
          tdClass: 'align-middle text-center',
          thClass: 'align-middle text-center'
        },
        {
          key: 'p_name',
          label: this.$t('transaction.p_name'),
          tdClass: 'product-name',
          thClass: 'product-name'
        },
        {
          key: 'p_unit',
          label: this.$t('transaction.p_unit'),
          tdClass: 'align-middle',
          thClass: 'align-middle'
        },
        {
          key: 'p_quantity',
          label: this.$t('transaction.p_quantity'),
          tdClass: 'align-middle text-right',
          thClass: 'align-middle text-right'
        },
        {
          key: 'p_unit_price',
          label: this.$t('transaction.p_unit_price'),
          tdClass: 'align-middle text-right text-nowrap',
          thClass: 'align-middle text-right'
        },
        {
          key: 'p_vat',
          label: this.$t('transaction.p_vat'),
          tdClass: 'align-middle text-right text-nowrap',
          thClass: 'align-middle text-right'
        },
        {
          key: 'p_total',
          label: this.$t('transaction.p_total'),
          tdClass: 'align-middle text-right text-nowrap',
          thClass: 'align-middle text-right'
        }
      ]
    }
  },
  methods: {
    productPrice(product) {
      let price = product.unit_price_tra
      let type = product.currency.currency_code
      return priceFormat(price, this.getLocale, type)
    },
    productVat(product) {
      let price = product.unit_price_tra
      let type = product.currency.currency_code
      let rate = product.vat_rate
      let currency = []
      currency.push({ code: product.currency_code, ask: product.currency_rate })
      //price = convertTL(price, type, currency)
      price = priceVat(price, rate) * (product.quantity ?? 1)
      return `${priceFormat(price, this.getLocale, type)}`
    },
    productPriceTRY(product) {
      let price = product.unit_price_tra
      let type = product.currency.currency_code
      let currency = []
      currency.push({ code: product.currency_code, ask: product.currency_rate })
      price = convertTL(price, type, currency)
      return priceFormat(price, this.getLocale, 'TRY')
    },
    productPriceWithVAT(product) {
      let price = product.unit_price_tra * product.quantity
      let type = product.currency.currency_code
      let rate = product.vat_rate
      let currency = []
      currency.push({ code: type, ask: product.currency_rate })
      //price = convertTL(price, type, currency)
      price = priceWithVat(price, rate)
      return priceFormat(price, this.getLocale, type)
    },
    showPrice(price) {
      return priceFormat(price, this.getLocale, 'TRY')
    },
    showRate(rate) {
      return (
        new Intl.NumberFormat(this.getLocale, {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2
        }).format(rate) + ' %'
      )
    },
    getPriceDetail(products, payment_type) {
      let currency = []

      let TOTAL_TRY = 0
      let TOTAL_TRY_VAT = 0
      let TOTAL_TRY_PWV = 0
      let TOTAL_TRY_PWVD = 0
      let TOTAL_USD = 0

      let USD = 0
      let USD_VAT = 0
      let USD_PWV = 0
      let USD_PWVD = 0

      let USD_TRY = 0
      let USD_VAT_TRY = 0
      let USD_PWV_TRY = 0
      let USD_PWVD_TRY = 0

      let DISCOUNT = 0
      let DISCOUNT_TRY = 0

      products.forEach(product => {
        currency = [{ code: product.currency.currency_code, ask: product.currency_rate }]

        let price = product.unit_price_tra * product.quantity

        let rate = product.vat_rate
        let type = product.currency.currency_code
        let p_try = convertTL(price, type, currency)

        let discount = price * 0.07
        let discount_try = convertTL(discount, type, currency)

        let p_vat = priceVat(payment_type === 1 ? price - discount : price, rate)
        let p_vat_try = convertTL(p_vat, type, currency)

        let pwv = priceWithVat(payment_type === 1 ? price - discount : price, rate)
        let pwv_try = convertTL(pwv, type, currency)

        TOTAL_TRY_VAT += p_vat_try
        TOTAL_TRY += p_try
        TOTAL_USD += pwv

        if (payment_type === 1) {
          DISCOUNT += discount
          DISCOUNT_TRY += discount_try
        }

        switch (type.toUpperCase()) {
          case 'USD':
            USD += price
            USD_VAT += p_vat
            USD_PWV += pwv
            USD_PWVD += pwv

            USD_TRY += p_try
            USD_VAT_TRY += p_vat_try
            USD_PWV_TRY += pwv_try
            USD_PWVD_TRY += pwv_try
            break
        }
      })

      TOTAL_TRY_PWV = convertTL(TOTAL_USD, 'USD', currency)
      TOTAL_TRY_PWVD = convertTL(TOTAL_USD, 'USD', currency)

      let TOTAL_TRY_SHOW = priceFormat(TOTAL_TRY, this.getLocale, 'TRY')
      let TOTAL_TRY_VAT_SHOW = priceFormat(TOTAL_TRY_VAT, this.getLocale, 'TRY')
      let TOTAL_TRY_PWVD_SHOW = priceFormat(TOTAL_TRY_PWVD, this.getLocale, 'TRY')

      let USD_SHOW = priceFormat(USD, this.getLocale, 'USD')
      let USD_VAT_SHOW = priceFormat(USD_VAT, this.getLocale, 'USD')
      let USD_PWVD_SHOW = priceFormat(USD_PWVD, this.getLocale, 'USD')

      let USD_TRY_SHOW = priceFormat(USD_TRY, this.getLocale, 'TRY')
      let USD_VAT_TRY_SHOW = priceFormat(USD_VAT_TRY, this.getLocale, 'TRY')
      let USD_PWVD_TRY_SHOW = priceFormat(USD_PWVD_TRY, this.getLocale, 'TRY')

      let DISCOUNT_SHOW = priceFormat(DISCOUNT, this.getLocale, 'USD')
      let DISCOUNT_TRY_SHOW = priceFormat(DISCOUNT_TRY, this.getLocale, 'TRY')

      return {
        TOTAL_TRY,
        TOTAL_TRY_SHOW,
        TOTAL_TRY_VAT,
        TOTAL_TRY_VAT_SHOW,
        TOTAL_TRY_PWVD,
        TOTAL_TRY_PWVD_SHOW,

        USD,
        USD_SHOW,
        USD_VAT,
        USD_VAT_SHOW,
        USD_PWV,
        USD_PWVD_SHOW,

        USD_TRY,
        USD_TRY_SHOW,
        USD_VAT_TRY,
        USD_VAT_TRY_SHOW,
        USD_PWVD_TRY,
        USD_PWVD_TRY_SHOW,

        DISCOUNT,
        DISCOUNT_SHOW,
        DISCOUNT_TRY,
        DISCOUNT_TRY_SHOW
      }
    }
  }
}
</script>
